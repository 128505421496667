import React from 'react'

export function ValidateNonprofitDetails(inputs) {
  let errorMessages = []
  if (inputs.nonprofitName.length == 0) {
    errorMessages.push("* Please provide an input for the Nonprofit Name field")
  }
  if (inputs.taxID.length == 0) {
    errorMessages.push("* Please provide an input for the Tax ID/EIN field")
  }
  if (inputs.taxID.length != 9) {
    errorMessages.push("* Please provide 9 digits for the Tax ID/EIN field")
  }
  if (inputs.website.length == 0) {
    errorMessages.push("* Please provide an input for the Website field")
  }
  if (inputs.incorporationDate.length == 0) {
    errorMessages.push("* Please provide an input for the Incorporation Date field")
  }
  if (inputs.nonprofitAddressLine1.length == 0) {
    errorMessages.push("* Please provide an input for the Address Line 1 field")
  }
  if (inputs.nonprofitCity.length == 0) {
    errorMessages.push("* Please provide an input for the City field")
  }
  if (inputs.nonprofitState.length == 0) {
    errorMessages.push("* Please provide an input for the State field")
  }
  if (inputs.nonprofitZip.length == 0) {
    errorMessages.push("* Please provide an input for the Zip Code field")
  }
  if (inputs.description.length == 0) {
    errorMessages.push("* Please provide an input for the Nonprofit Description field")
  }
  console.log('inside error msg', errorMessages)
  return errorMessages;
}

export function ValidateRepresentativeDetails(inputs) {
  console.log('im insde')
  let errorMessages = []
  if (inputs.repFirstName.length == 0) {
    errorMessages.push("* Please provide an input for the First Name field")
  }
  if (inputs.repLastName.length == 0) {
    errorMessages.push("* Please provide an input for the Last Name field")
  }
  if (inputs.title.length == 0) {
    errorMessages.push("* Please provide an input for the Position Title field")
  }
  if (inputs.ssn.length == 0) {
    errorMessages.push("* Please provide an input for the Social Security Number field")
  }
  else if (inputs.ssn.length != 9) {
    errorMessages.push("* Please provide 9 digits for the Social Security Number field")
  }
  if (inputs.dob.length == 0) {
    errorMessages.push("* Please provide an input for the Date of Birth field")
  }
  else if (inputs.dob.replace(/\D/g, '').length != 8) {
    errorMessages.push("* Please provide a valid input for the Date of Birth field")
  }
  if (inputs.phone.length == 0) {
    errorMessages.push("* Please provide an input for the Phone field")
  }
  else if (inputs.phone.length != 10) {
    errorMessages.push("* Please provide 10 digits for the Phone field")
  }
  if (inputs.email.length == 0) {
    errorMessages.push("* Please provide an input for the Email field")
  }
  else if (!inputs.email.includes('@') || !inputs.email.includes('.')) {
    errorMessages.push("* Please provide a valid Email for the Email field")
  }
  if (inputs.repAddressLine1.length == 0) {
    errorMessages.push("* Please provide an input for the Address Line 1 field")
  }
  if (inputs.repCity.length == 0) {
    errorMessages.push("* Please provide an input for the City field")
  }
  if (inputs.repState.length == 0) {
    errorMessages.push("* Please provide an input for the State field")
  }
  if (inputs.repZip.length == 0) {
    errorMessages.push("* Please provide an input for the Zip Code field")
  }
  console.log('inside error msg', errorMessages)
  return errorMessages;
}

export function ValidateDonationProcessingDetails(inputs) {
  let errorMessages = []
  if (inputs.statementDescriptor.length == 0) {
    errorMessages.push("* Please provide an input for the Default Statement Descriptor field")
  }
  if (inputs.cardVolume.length == 0) {
    errorMessages.push("* Please provide an input for the Annual Card Volume field")
  }
  if (inputs.averageCardAmount.length == 0) {
    errorMessages.push("* Please provide an input for the Average Card Transfer Amount field")
  }
  if (inputs.maxTransactionAmount.length == 0) {
    errorMessages.push("* Please provide an input for the Max Transaction Amount field")
  }
  if (inputs.achVolume.length == 0) {
    errorMessages.push("* Please provide an input for the Annual ACH Volume field")
  }
  if (inputs.averageACHAmount.length == 0) {
    errorMessages.push("* Please provide an input for the Average ACH Transfer Amount field")
  }
  console.log('inside error msg', errorMessages)
  return errorMessages;
}

export function ValidateBankDetails(inputs) {
  let errorMessages = []
  if (inputs.bankFirstName.length == 0) {
    errorMessages.push("* Please provide an input for the First Name field")
  }
  if (inputs.bankLastName.length == 0) {
    errorMessages.push("* Please provide an input for the Last Name field")
  }
  if (inputs.accountNumber.length == 0) {
    errorMessages.push("* Please provide an input for the Account Number field")
  }
  if (inputs.accountNumber.length > 17) {
    errorMessages.push("* The Account Number field cannot be longer than 17 digits")
  }
  if (inputs.routingNumber.length == 0) {
    errorMessages.push("* Please provide an input for the Routing Number field")
  }
  if (inputs.routingNumber.length != 9) {
    errorMessages.push("* Please provide 10 digits for the Routing Number field")
  }
  console.log('inside error msg', errorMessages)
  return errorMessages;
}

export function ValidateSummary(inputs) {
  let errorMessagesList = []
  errorMessagesList.push(ValidateNonprofitDetails(inputs))
  errorMessagesList.push(ValidateRepresentativeDetails(inputs))
  errorMessagesList.push(ValidateDonationProcessingDetails(inputs))
  errorMessagesList.push(ValidateBankDetails(inputs))
  return errorMessagesList;
}