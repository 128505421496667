/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ValidateSummary } from '../Tools/Validate'




export default function Confirmation(props) {
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                        <div className="">
                            <h3 className="col-start-2 text-center text-3xl leading-6 font-medium text-gray-900">Your application for {props.nonprofitName} has been submitted successfully!</h3>
                            <br />&nbsp;<br />
                            <p className="col-start-2 text-center mt-1 text-sm text-gray-500">We'll notify you once your application has been reviewed. Thank you for choosing CharityStack!</p>


                        </div>




                    </div>
                    <div className="bg-gray-50 text-center">

                        <div className="px-4 py-3 bg-gray-50 sm:px-6">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
