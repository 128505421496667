import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import NonprofitDetails from './Components/NonprofitDetails'
import RepresentativeDetails from './Components/RepresentativeDetails'
import DonationProcessingDetails from './Components/DonationProcessingDetails'
import BankDetails from './Components/BankDetails'
import Summary from './Components/Summary'
import Confirmation from './Components/Confirmation'
import ScrollToTop from './Tools/ScrollToTop';
import { CreditCardIcon, UserCircleIcon, ViewGridAddIcon, CurrencyDollarIcon, OfficeBuildingIcon, ClipboardListIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  //nonprofit details
  const [nonprofitName, setNonprofitName] = useState('')
  const [taxID, setTaxID] = useState('')
  const [website, setWebsite] = useState('')
  const [incorporationDate, setIncorporationDate] = useState('')
  const [nonprofitAddressLine1, setNonprofitAddressLine1] = useState('')
  const [nonprofitAddressLine2, setNonprofitAddressLine2] = useState('')
  const [nonprofitCity, setNonprofitCity] = useState('')
  const [nonprofitState, setNonprofitState] = useState('')
  const [nonprofitZip, setNonprofitZip] = useState('')
  const [description, setDescription] = useState('')

  //representative details
  const [repFirstName, setRepFirstName] = useState('')
  const [repLastName, setRepLastName] = useState('')
  const [title, setTitle] = useState('')
  const [ssn, setSSN] = useState('')
  const [dob, setDOB] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [repAddressLine1, setRepAddressLine1] = useState('')
  const [repAddressLine2, setRepAddressLine2] = useState('')
  const [repCity, setRepCity] = useState('')
  const [repState, setRepState] = useState('')
  const [repZip, setRepZip] = useState('')

  //donation processing details
  const [statementDescriptor, setStatementDescriptor] = useState('')
  const [cardVolume, setCardVolume] = useState('')
  const [averageCardAmount, setAverageCardAmount] = useState('')
  const [maxTransactionAmount, setMaxTransactionAmount] = useState('')
  const [achVolume, setACHVolume] = useState('')
  const [averageACHAmount, setAverageACHAmount] = useState('')

  //bank details
  const [bankFirstName, setBankFirstName] = useState('')
  const [bankLastName, setBankLastName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')

  const navigation = [
    { name: 'Nonprofit Details', href: '/', icon: OfficeBuildingIcon, current: true },
    { name: 'Nonprofit Representative Details', href: '/nonprofit-representative-details', icon: UserCircleIcon, current: false },
    { name: 'Donation Processing Details', href: '/donation-processing-details', icon: CreditCardIcon, current: false },
    { name: 'Bank Details', href: '/bank-details', icon: CurrencyDollarIcon, current: false },
    { name: 'Summary', href: '#', icon: ClipboardListIcon, current: false },
  ]
  console.log('change')
  console.log('parent nonprofit name: '+ nonprofitName)
  return (
    <Router>
      <html className='scroll-smooth'>
      <ScrollToTop />
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <Routes>
            <Route path="/" element={<NonprofitDetails
                                      nonprofitName={nonprofitName}
                                      taxID={taxID}
                                      website={website}
                                      incorporationDate={incorporationDate}
                                      nonprofitAddressLine1={nonprofitAddressLine1}
                                      nonprofitAddressLine2={nonprofitAddressLine2}
                                      nonprofitCity={nonprofitCity}
                                      nonprofitState={nonprofitState}
                                      nonprofitZip={nonprofitZip}
                                      description={description}
                                      setNonprofitName={setNonprofitName}
                                      setTaxID={setTaxID}
                                      setWebsite={setWebsite}
                                      setIncorporationDate={setIncorporationDate}
                                      setNonprofitAddressLine1={setNonprofitAddressLine1}
                                      setNonprofitAddressLine2={setNonprofitAddressLine2}
                                      setNonprofitCity={setNonprofitCity}
                                      setNonprofitState={setNonprofitState}
                                      setNonprofitZip={setNonprofitZip}
                                      setDescription={setDescription} />}></Route>
            <Route path="/nonprofit-representative-details" element={<RepresentativeDetails 
                                                                      repFirstName={repFirstName}
                                                                      repLastName={repLastName}
                                                                      title={title}
                                                                      ssn={ssn}
                                                                      dob={dob}
                                                                      phone={phone}
                                                                      email={email}
                                                                      repAddressLine1={repAddressLine1}
                                                                      repAddressLine2={repAddressLine2}
                                                                      repCity={repCity}
                                                                      repState={repState}
                                                                      repZip={repZip}
                                                                      setRepFirstName={setRepFirstName}
                                                                      setRepLastName={setRepLastName}
                                                                      setTitle={setTitle}
                                                                      setSSN={setSSN}
                                                                      setDOB={setDOB}
                                                                      setPhone={setPhone}
                                                                      setEmail={setEmail}
                                                                      setRepAddressLine1={setRepAddressLine1}
                                                                      setRepAddressLine2={setRepAddressLine2}
                                                                      setRepCity={setRepCity}
                                                                      setRepState={setRepState}
                                                                      setRepZip={setRepZip} />}></Route>
            <Route path="/donation-processing-details" element={<DonationProcessingDetails 
                                                                statementDescriptor={statementDescriptor}
                                                                cardVolume={cardVolume}
                                                                averageCardAmount={averageCardAmount}
                                                                maxTransactionAmount={maxTransactionAmount}
                                                                achVolume={achVolume}
                                                                averageACHAmount={averageACHAmount}
                                                                setStatementDescriptor={setStatementDescriptor}
                                                                setCardVolume={setCardVolume}
                                                                setAverageCardAmount={setAverageCardAmount}
                                                                setMaxTransactionAmount={setMaxTransactionAmount}
                                                                setACHVolume={setACHVolume}
                                                                setAverageACHAmount={setAverageACHAmount}
                                                                />}></Route>
            <Route path="/bank-details" element={<BankDetails 
                                                  bankFirstName={bankFirstName}
                                                  bankLastName={bankLastName}
                                                  accountNumber={accountNumber}
                                                  routingNumber={routingNumber}
                                                  setBankFirstName={setBankFirstName}
                                                  setBankLastName={setBankLastName}
                                                  setAccountNumber={setAccountNumber}
                                                  setRoutingNumber={setRoutingNumber}/>}></Route>
            <Route path="/summary" element={<Summary 
                                                  nonprofitName={nonprofitName}
                                                  taxID={taxID}
                                                  website={website}
                                                  incorporationDate={incorporationDate}
                                                  nonprofitAddressLine1={nonprofitAddressLine1}
                                                  nonprofitAddressLine2={nonprofitAddressLine2}
                                                  nonprofitCity={nonprofitCity}
                                                  nonprofitState={nonprofitState}
                                                  nonprofitZip={nonprofitZip}
                                                  description={description}
                                                  repFirstName={repFirstName}
                                                  repLastName={repLastName}
                                                  title={title}
                                                  ssn={ssn}
                                                  dob={dob}
                                                  phone={phone}
                                                  email={email}
                                                  repAddressLine1={repAddressLine1}
                                                  repAddressLine2={repAddressLine2}
                                                  repCity={repCity}
                                                  repState={repState}
                                                  repZip={repZip}
                                                  statementDescriptor={statementDescriptor}
                                                  cardVolume={cardVolume}
                                                  averageCardAmount={averageCardAmount}
                                                  maxTransactionAmount={maxTransactionAmount}
                                                  achVolume={achVolume}
                                                  averageACHAmount={averageACHAmount}
                                                  bankFirstName={bankFirstName}
                                                  bankLastName={bankLastName}
                                                  accountNumber={accountNumber}
                                                  routingNumber={routingNumber}
                                                  />}></Route>
              <Route path="/confirmation" element={<Confirmation 
                                                  nonprofitName={nonprofitName}  
                                                  />}></Route>
          </Routes>
        </div>
        </html>
    </Router>
  );
}

export default App;
