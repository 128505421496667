/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomCombobox from '../Tools/CustomCombobox';
import { CreditCardIcon, UserCircleIcon, ViewGridAddIcon, CurrencyDollarIcon, OfficeBuildingIcon, ClipboardListIcon } from '@heroicons/react/outline';
import { ValidateRepresentativeDetails } from '../Tools/Validate'


const states = ['AL', 'AK', 'AZ', 'AR', 'AS', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'CM',
    'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'TT', 'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY']



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function RepresentativeDetails(props) {
    const navigate = useNavigate();
    const handleNonprofitDetails = useCallback(() => navigate('/', { replace: true }), [navigate]);
    const handleNonprofitRepresentativeDetails = useCallback(() => navigate('/nonprofit-representative-details', { replace: true }), [navigate]);
    const handleDonationProcessingDetails = useCallback(() => navigate('/donation-processing-details', { replace: true }), [navigate]);
    const handleBankDetails = useCallback(() => navigate('/bank-details', { replace: true }), [navigate]);
    const handleSummary = useCallback(() => navigate('/summary', { replace: true }), [navigate]);


    const navigation = [
        { name: 'Nonprofit Details', link: handleNonprofitDetails, icon: OfficeBuildingIcon, current: false },
        { name: 'Nonprofit Representative Details', link: handleNonprofitRepresentativeDetails, icon: UserCircleIcon, current: true },
        { name: 'Donation Processing Details', link: handleDonationProcessingDetails, icon: CreditCardIcon, current: false },
        { name: 'Bank Details', link: handleBankDetails, icon: CurrencyDollarIcon, current: false },
        { name: 'Summary', link: handleSummary, icon: ClipboardListIcon, current: false },
    ]

    const [errorMessages, setErrorMessages] = useState([])
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        // <Link to={item.href} >
                        <a
                            key={item.name}
                            className={classNames(
                                item.current
                                    ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                                'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            onClick={(e) => {
                                e.preventDefault();
                                const errorMessages = ValidateRepresentativeDetails(props);
                                if (errorMessages.length == 0) {
                                    item.link();
                                }
                                else {
                                    setErrorMessages(errorMessages)
                                    window.scrollTo(0, document.body.scrollHeight);
                                }
                            }}
                        >
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? 'text-indigo-500 group-hover:text-indigo-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                        // </Link>
                    ))}
                </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Tell us about yourself (AKA the nonprofit representative)</h3>
                                <p className="mt-1 text-sm text-gray-500">The information CharityStack collects about your nonprofit helps us meet requirements from regulators, financial partners, and our Services Agreement.</p>
                            </div>

                            <div className="grid grid-cols-5 gap-5">
                                <div className="col-span-5 sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        First Name
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repFirstName}
                                        onChange={(e) => { props.setRepFirstName(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-2">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Last Name
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repLastName}
                                        onChange={(e) => { props.setRepLastName(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="corporate-title" className="block text-sm font-medium text-gray-700">
                                        Position Title
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="corporate-title"
                                        id="corporate-title"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.title}
                                        onChange={(e) => { props.setTitle(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="ssn" className="block text-sm font-medium text-gray-700">
                                        Social Security Number
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="number"
                                        name="ssn"
                                        id="ssn"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.ssn}
                                        onChange={(e) => props.setSSN(e.target.value.slice(0, Math.min(e.target.value.length, 9)))}
                                    />
                                </div>


                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                        Date of Birth
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="date"
                                        name="dob"
                                        id="dob"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.dob}
                                        onChange={(e) => { props.setDOB(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                        Phone
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="number"
                                        name="phone"
                                        id="phone"
                                        autoComplete="tel-national"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.phone}
                                        //onChange={(e) => { props.setPhone(e.target.value) }}
                                        onChange={(e) => props.setPhone(e.target.value.slice(0, Math.min(e.target.value.length, 10)))}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.email}
                                        onChange={(e) => { props.setEmail(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="address-line1" className="block text-sm font-medium text-gray-700">
                                        Address Line 1
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="address-line1"
                                        id="address-line1"
                                        autoComplete="address-line1"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repAddressLine1}
                                        onChange={(e) => { props.setRepAddressLine1(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="address-line2" className="block text-sm font-medium text-gray-700">
                                        Address Line 2
                                    </label>
                                    <input
                                        type="text"
                                        name="address-line2"
                                        id="address-line2"
                                        autoComplete="address-line2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repAddressLine2}
                                        onChange={(e) => { props.setRepAddressLine2(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        City
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        autoComplete="address-level2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repCity}
                                        onChange={(e) => { props.setRepCity(e.target.value) }}
                                    />
                                </div>

                                <div className="col-span-5 sm:col-span-3 grid">
                                    <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                        State
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <CustomCombobox id="region" items={states} selected={props.repState} setSelected={props.setRepState} />
                                </div>

                                <div className="col-span-5 sm:col-span-3">
                                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                        Zip Code
                                        <div className="inline text-sm font-medium text-red-700">
                                            &nbsp;*
                                        </div>
                                    </label>
                                    <input
                                        type="number"
                                        name="postal-code"
                                        id="postal-code"
                                        autoComplete="postal-code"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={props.repZip}
                                        onChange={(e) => props.setRepZip(e.target.value.slice(0, Math.min(e.target.value.length, 7)))}
                                    />
                                </div>
                                {errorMessages.map(errorMessage => {
                                    return (
                                        <div className="col-span-5 sm:col-span-3" >
                                            <p htmlFor="nonprofit-description" className="block text-sm font-medium text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    )
                                })}



                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Link to="/donation-processing-details">
                                <button
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const errorMessages = ValidateRepresentativeDetails(props);
                                        if (errorMessages.length == 0) {
                                            handleDonationProcessingDetails();
                                        }
                                        else {
                                            setErrorMessages(errorMessages)
                                            window.scrollTo({top:document.body.scrollHeight, behavior:'smooth'});
                                        }
                                    }}
                                >
                                    Continue
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
