/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomCombobox from '../Tools/CustomCombobox';
import { CreditCardIcon, UserCircleIcon, ViewGridAddIcon, CurrencyDollarIcon, OfficeBuildingIcon, ClipboardListIcon } from '@heroicons/react/outline';
import { ValidateSummary } from '../Tools/Validate'

const finixTermsOfService = "https://finix-hosted-content.s3.amazonaws.com/flex/v2/finix-terms-of-service.html"
const dotNGOTermsOfService = "https://www.iubenda.com/terms-and-conditions/64743728"

const states = ['AL', 'AK', 'AZ', 'AR', 'AS', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'CM',
    'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'TT', 'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY']



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Summary(props) {
    const navigate = useNavigate();
    const handleNonprofitDetails = useCallback(() => navigate('/', { replace: true }), [navigate]);
    const handleNonprofitRepresentativeDetails = useCallback(() => navigate('/nonprofit-representative-details', { replace: true }), [navigate]);
    const handleDonationProcessingDetails = useCallback(() => navigate('/donation-processing-details', { replace: true }), [navigate]);
    const handleBankDetails = useCallback(() => navigate('/bank-details', { replace: true }), [navigate]);
    const handleSummary = useCallback(() => navigate('/summary', { replace: true }), [navigate]);
    const handleConfirmation = useCallback(() => navigate('/confirmation', { replace: true }), [navigate]);

    const navigation = [
        { name: 'Nonprofit Details', link: handleNonprofitDetails, icon: OfficeBuildingIcon, current: false },
        { name: 'Nonprofit Representative Details', link: handleNonprofitRepresentativeDetails, icon: UserCircleIcon, current: false },
        { name: 'Donation Processing Details', link: handleDonationProcessingDetails, icon: CreditCardIcon, current: false },
        { name: 'Bank Details', link: handleBankDetails, icon: CurrencyDollarIcon, current: false },
        { name: 'Summary', href: '/summary', icon: ClipboardListIcon, current: true },
    ]

    const [consent, setConsent] = useState(false)

    const [errorMessages, setErrorMessages] = useState([])
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                <nav className="space-y-1">
                    {navigation.map((item) => (
                        // <Link to={item.href} >
                        <a
                            key={item.name}
                            className={classNames(
                                item.current
                                    ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                                'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            onClick={(e) => { item.link() }}
                        >
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? 'text-indigo-500 group-hover:text-indigo-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            <span className="truncate">{item.name}</span>
                        </a>
                        // </Link>
                    ))}
                </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <form action="#" method="POST">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                            <div className="grid grid-cols-3">
                                <h3 className="col-start-2 text-4xl leading-6 font-medium text-gray-900">Summary</h3>
                                <br />&nbsp;<br />

                                <h3 className="leading col-start-1 text-lg leading-6 font-medium text-gray-900">Nonprofit Details</h3>
                                <div></div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Nonprofit Name: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitName}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Tax ID/EIN: </p>
                                    <p className="inline mt-1 text-md text-black">{props.taxID}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Website: </p>
                                    <p className="inline mt-1 text-md text-black">{props.website}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Incorporation Date: </p>
                                    <p className="inline mt-1 text-md text-black">{props.incorporationDate}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Address Line 1: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitAddressLine1}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Address Line 2: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitAddressLine2}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">City: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitCity}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">State: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitState}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Zip Code: </p>
                                    <p className="inline mt-1 text-md text-black">{props.nonprofitZip}</p>
                                </div>

                                <h3 className="col-start-1 text-lg leading-6 font-medium text-gray-900">Nonprofit Representative Details</h3>
                                <div></div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">First Name: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repFirstName}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Last Name: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repLastName}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Title: </p>
                                    <p className="inline mt-1 text-md text-black">{props.title}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Social Security Number: </p>
                                    <p className="inline mt-1 text-md text-black">{props.ssn}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Date of Birth: </p>
                                    <p className="inline mt-1 text-md text-black">{props.dob}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-mdtext-gray-500">Phone: </p>
                                    <p className="inline mt-1 text-mdtext-black">{props.phone}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Email: </p>
                                    <p className="inline mt-1 text-md text-black">{props.email}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Address Line 1: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repAddressLine1}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Address Line 2: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repAddressLine2}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">City: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repCity}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">State: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repState}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Zip Code: </p>
                                    <p className="inline mt-1 text-md text-black">{props.repZip}</p>
                                </div>

                                <h3 className="col-start-1 text-lg leading-6 font-medium text-gray-900">Donation Processing Details</h3>
                                <div></div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Default Statement Descriptor: </p>
                                    <p className="inline mt-1 text-md text-black">{props.statementDescriptor}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Annual Card Volume ($): </p>
                                    <p className="inline mt-1 text-md text-black">{props.cardVolume}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Average Card Transaction Amount ($): </p>
                                    <p className="inline mt-1 text-md text-black">{props.averageCardAmount}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Max Transaction Amount ($): </p>
                                    <p className="inline mt-1 text-md text-black">{props.maxTransactionAmount}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Annual ACH Volume ($): </p>
                                    <p className="inline mt-1 text-md text-black">{props.achVolume}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Average ACH Transfer Amount ($): </p>
                                    <p className="inline mt-1 text-md text-black">{props.averageACHAmount}</p>
                                </div>

                                <h3 className="col-start-1 text-lg leading-6 font-medium text-gray-900">Bank Details</h3>
                                <div></div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">First Name: </p>
                                    <p className="inline mt-1 text-md text-black">{props.bankFirstName}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Last Name: </p>
                                    <p className="inline mt-1 text-md text-black">{props.bankLastName}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Account Number: </p>
                                    <p className="inline mt-1 text-md text-black">{props.accountNumber}</p>
                                </div>
                                <div className="col-start-2 block">
                                    <p className="inline mt-1 text-md text-gray-500">Routing Number: </p>
                                    <p className="inline mt-1 text-md text-black">{props.routingNumber}</p>
                                </div>
                            </div>


                            <div >
                                {errorMessages.map(errorMessage => {
                                    return (
                                        <div className="col-span-5 sm:col-span-3" >
                                            <p htmlFor="nonprofit-description" className="block text-sm font-medium text-red-700">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>



                        </div>
                        <div className="bg-gray-50 text-center">
                            <div className="relative items-center">
                                <div className="ml-3">
                                    <input
                                        id="comments"
                                        aria-describedby="comments-description"
                                        name="comments"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-4 w-4 mr-2 text-indigo-600 border-gray-300 rounded"
                                        defaultChecked={consent}
                                        onChange={(e) => setConsent(!consent)}
                                    />
                                    {/* <label htmlFor="comments" className="font-medium text-gray-700"> */}
                                    <label htmlFor="comments" className="mt-1 text-sm text-gray-700">By checking this box, you agree to our <a className="underline" target="_blank" href={dotNGOTermsOfService}>Terms of Service</a> and the <a className="underline" target="_blank" href={finixTermsOfService}>Finix Terms of Service</a>.</label>
                                    
                                    {/* </label> */}
                                    {/* <p id="comments-description" className="text-gray-500">
                                        Get notified when someones posts a comment on a posting.
                                    </p> */}
                                </div>
                            </div>
                            <p className="col-start-2 mt-1 text-xs text-gray-500">CharityStack, Inc. partners with Finix Payments to handle payment processing, merchant underwriting, and payouts.</p>
                            <p className="col-start-2 mt-1 text-xs text-gray-500">The transaction fee for your nonprofit will be 2.9% + $0.30 per donation.</p>

                            <div className="px-4 py-3 bg-gray-50 sm:px-6">
                                <Link to="/donation-processing-details">
                                    <button
                                        type="submit"
                                        className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            const errorMessagesList = ValidateSummary(props);
                                            let hasErrors = false
                                            let displayErrors = []
                                            console.log('err msg list', errorMessagesList)
                                            errorMessagesList.map((errMsgs, index) => {
                                                console.log('errmsgs', errMsgs)
                                                if (errMsgs.length != 0) {
                                                    hasErrors = true
                                                    if (index == 0) {
                                                        displayErrors.push('*************** Nonprofit Details Errors ***************')
                                                    }
                                                    else if (index == 1) {
                                                        displayErrors.push('******* Nonprofit Representative Details Errors ********')
                                                    }
                                                    else if (index == 2) {
                                                        displayErrors.push('********** Donation Processing Details Errors **********')
                                                    }
                                                    else {
                                                        displayErrors.push('****************** Bank Details Errors ******************')
                                                    }
                                                    displayErrors = displayErrors.concat(errMsgs)
                                                }
                                            })
                                            if (!consent) {
                                                hasErrors = true
                                                displayErrors.push('****************** Summary Page Errors *******************')
                                                displayErrors.push('* Please check the box below to consent to our Terms of Service and the Finix Terms of Service before submitting your application.')
                                            }
                                            if (hasErrors) {
                                                setErrorMessages(displayErrors)
                                                window.scrollTo({top:document.body.scrollHeight, behavior:'smooth'});
                                            }
                                            else {
                                                setErrorMessages([])
                                                //insert code to do post request to server, then on success load handleConfirmation

                                                const requestOptions = {
                                                    method: 'POST',
                                                    headers: { 'accept': '*/*', 'content-type': 'application/json' },
                                                    body: JSON.stringify({
                                                        nonprofitName: props.nonprofitName,
                                                        taxID: props.taxID,
                                                        website: props.website,
                                                        incorporationDate: props.incorporationDate,
                                                        nonprofitAddressLine1: props.nonprofitAddressLine1,
                                                        nonprofitAddressLine2: props.nonprofitAddressLine2,
                                                        nonprofitCity: props.nonprofitCity,
                                                        nonprofitState: props.nonprofitState,
                                                        nonprofitZip: props.nonprofitZip,
                                                        description: props.description,
                                                        repFirstName: props.repFirstName,
                                                        repLastName: props.repLastName,
                                                        title: props.title,
                                                        ssn: props.ssn,
                                                        dob: props.dob,
                                                        phone: props.phone,
                                                        email: props.email,
                                                        repAddressLine1: props.repAddressLine1,
                                                        repAddressLine2: props.repAddressLine2,
                                                        repCity: props.repCity,
                                                        repState: props.repState,
                                                        repZip: props.repZip,
                                                        statementDescriptor: props.statementDescriptor,
                                                        cardVolume: props.cardVolume,
                                                        averageCardAmount: props.averageCardAmount,
                                                        maxTransactionAmount: props.maxTransactionAmount,
                                                        achVolume: props.achVolume,
                                                        averageACHAmount: props.averageACHAmount,
                                                        bankFirstName: props.bankFirstName,
                                                        bankLastName: props.bankLastName,
                                                        accountNumber: props.accountNumber,
                                                        routingNumber: props.routingNumber
                                                    }),
                                                };
                                                const url = "https://5tdmz4ittf.execute-api.us-east-2.amazonaws.com/prod/submit"
                                                const response = await fetch(url, requestOptions)
                                                const data = await response.json()
                                                console.log('data is', data)
                                                handleConfirmation();
                                            }
                                        }}
                                    >
                                        Submit
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
